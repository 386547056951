export const userModule = {
  namespaced: true,
  state: () => ({
    userData: {
      name: "",
      phone: "",
      email: "",
      address: "",
      status: "",
      userType: "",
    },
  }),
  mutations: {
    SET_USER_DATA(state, payload) {
      state.userData = { ...state.userData, ...payload };
    },
  },
  actions: {},
  getters: {},
};
