<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
// import { getTokenCookie, getProfileCookie } from "./utils/cookies.js";

export default {
  name: "App",
  created() {
    // check login
    console.log(process.env.NODE_ENV);
  },
};
</script>
