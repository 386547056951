import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// global css
import "./assets/styles/global.scss";

import vuetify from "./plugins/vuetify";
import downloadExcel from "./plugins/downloadExcel";

new Vue({
  router,
  store,
  vuetify,
  downloadExcel,
  render: (h) => h(App),
}).$mount("#app");
