export const orderModule = {
  namespaced: true,
  state: () => ({
    isWalkin: true,
    idOrder: "",
    idShipper: "",
    idRecipient: "",
    idCollies: [],
    shipperData: {
      shipperName: "",
      shipperPhone: "",
      shipperEmail: "",
      shipperCity: "",
      shipperCityId: "",
      shipperKelurahan: "",
      shipperKecamatan: "",
      shipperKecamatanId: "",
      shipperKelurahanId: "",
      shipperPostCode: "",
      shipperAddress: "",
    },
    recipientData: {
      recipientName: "",
      recipientPhone: "",
      recipientEmail: "",
      recipientCity: "",
      recipientCityId: "",
      recipientKelurahan: "",
      recipientKecamatanId: "",
      recipientKelurahanId: "",
      recipientPostCode: "",
      recipientAddress: "",
    },
    itemData: {
      moda: "Darat",
      description: "",
      instruction: "",
      discount: 0,
      tax: 0,
      estimateValue: 0,
      collies: [],
      totalWeight: 0,
      packingPrice: 0,
      kargoPrice: 0,
      totalPrice: 0,
      isInsurance: false,
      insurance: 0,
    },
  }),
  mutations: {
    SET_SHIPPER(state, payload) {
      state.shipperData = { ...state.shipperData, ...payload };
    },
    SET_RECIPIENT(state, payload) {
      state.recipientData = { ...state.recipientData, ...payload };
    },
    SET_ITEM(state, payload) {
      state.itemData = { ...state.itemData, ...payload };
    },
    SET_WALKIN(state, payload) {
      state.isWalkin = payload.isWalkin;
      state.idOrder = payload.idOrder;
      state.idShipper = payload.idShipper;
      state.idRecipient = payload.idRecipient;
      state.idCollies = payload.idCollies;
    },
    CLEAR_DATA(state) {
      state.isWalkin = true;
      state.idOrder = "";
      state.idShipper = "";
      state.idRecipient = "";
      state.idCollies = [];
      state.shipperData = {
        shipperName: "",
        shipperPhone: "",
        shipperEmail: "",
        shipperCity: "",
        shipperCityId: "",
        shipperKelurahan: "",
        shipperKecamatanId: "",
        shipperKelurahanId: "",
        shipperPostCode: "",
        shipperAddress: "",
      };
      state.recipientData = {
        recipientName: "",
        recipientPhone: "",
        recipientEmail: "",
        recipientCity: "",
        recipientCityId: "",
        recipientKelurahan: "",
        recipientKecamatanId: "",
        recipientKelurahanId: "",
        recipientPostCode: "",
        recipientAddress: "",
      };
      state.itemData = {
        moda: "Darat",
        description: "",
        instruction: "",
        discount: 0,
        tax: 0,
        estimateValue: 0,
        collies: [],
        totalWeight: 0,
        packingPrice: 0,
        kargoPrice: 0,
        totalPrice: 0,
        isInsurance: false,
        insurance: 0,
      };
    },
  },
  actions: {},
  getters: {},
};
